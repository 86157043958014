<template>
  <About pesan="about" />
</template>

<script>
import About from "@/components/About.vue";

export default {
  components: {
    About,
  },
};
</script>
